import React from "react";
import { fillColor, PathIcon } from "./Icon";

// checkbox-circle-fill
export default function CheckboxCircleFill(props) {
  return (
    <PathIcon viewBox="0 0 57 57" {...props}>
      <path d="M28.5,52.25 C15.382875,52.25 4.75,41.617125 4.75,28.5 C4.75,15.382875 15.382875,4.75 28.5,4.75 C41.617125,4.75 52.25,15.382875 52.25,28.5 C52.25,41.617125 41.617125,52.25 28.5,52.25 Z M26.132125,38 L42.923375,21.206375 L39.565125,17.848125 L26.132125,31.2835 L19.41325,24.564625 L16.055,27.922875 L26.132125,38 Z" fill={fillColor(props)}></path>
    </PathIcon>
  );
}

CheckboxCircleFill.defaultProps = {
  colorDisabled: "var(--yellow-500)",
}
