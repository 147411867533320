import React from "react";
import * as moduleStyles from "./Hero.module.scss";
import { Image } from "../image/Image";
import brandImage from "./images/online-brand-guideline-builder@2x.png";
import { Button } from "../form/Button";
import { DesignersHero } from "../../fixtures";

export default function Hero(_props) {

  return (
    <div className={moduleStyles.container}>
      <div className={moduleStyles.inner}>
        <p className="text-lead grey-300 mt-none">Give your clients a practical way to access their brand assets through</p> 
        <h1>Actionable Online Brand Guidelines that just work</h1>
        <div className={moduleStyles.subtitle}>
          <p className="text-lead grey-300">Design and publish responsive online brand identity guidelines with downloadable assets in 10 minutes or less.</p>
          <p className="text-small orange-500">Join over 400 Brand Identity Designers including</p>
          <Designers designers={DesignersHero} />
        </div>
      </div>
      {/* TODO mobile image */}
      <img className={moduleStyles.brandImage}
        src={brandImage} alt="Logo presentation"
        width={1184}
      />
    </div>
  )
}

function Designers(props) {
  // TODO
  const elements = props.designers.map(({path, href}) => {
    const name = path.match(/designer-(.*)/)[1].replace("-", " ");

    return (
      <Button key={path} link to={href}>
        <Image rounded
          src={path}
          alt={name}
          title={name}
          width={48}
          height={48}
        />
      </Button>
    )
  });

  return <div className={moduleStyles.designers}>{elements}</div>
}