import React from "react"
import Hero from "../components/hero/Hero";
import Double from "../components/double/Double";
import Spacer from "../components/spacer/Spacer";
import Centered from "../components/centered/Centered";
import ExampleGrid from "../components/example-grid/ExampleGrid";
import BrandingPresentationImage from "../images/index/responsive-brand-presenations@2x.png";
import AssetsImage from "../images/index/interactive-brand-guidelines@2x.png";
import AssetsModulesImage from "../images/index/centralized-brand-assets@2x.png";
import SharingLogosImage from "../images/index/share-brandpage-url@2x.png";
import ShowcaseImage from "../images/index/showcase-brand-identity-design-work@2x.png";
import BuilderImage from "../images/index/branding-drag-drop-builder.png";
import {ExamplesData, SenjaTestimonialsId} from "../fixtures";
import { Testimonials } from "../components/testimonial/Testimonials";
import presentation1 from "../images/index/brand-presentation-builder.svg";
import presentation2 from "../images/index/brand-asset-handoff.svg";
import presentation3 from "../images/index/transfer-brand-assets.svg";
import { Button } from "../components/form/Button";
import Page from "../components/page/Page";
import PaywallContainer from "../components/paywall/PaywallContainer";
import "../styles/index.scss";


// markup
const IndexPage = () => {
  return (
    <Page>
      <Spacer amount="90" hideOnNarrowDevices={true} />

      <Hero />

      <Spacer amount="100" />

      <Centered>
        <h2 className="max-width-spacing-screen-big">Spend more time focusing on actual design work, not designing online brand guidelines</h2>

        <Spacer amount="90"/>
        <div className="design3">
          <div>
            <img src={presentation1} alt="Download brand assets" width={300} height={214} />
            <p>Create custom, stylish online brand identity guidelines that are responsive and host downloadable brand assets with very little effort.</p>
          </div>
          <div>
            <img src={presentation2} alt="Brand Identity Handoff" width={300} height={214} />
            <p>Provide your clients with a simple and practical way to understand their brand guidelines and download the assets they need in the format they need.</p>
          </div>
          <div>
            <img src={presentation3} alt="Share Brand Guidelines" width={300} height={214} />
            <p>Forget about ever having to send clunky PDFs or Zip files and simply share a URL link with your clients that you can easily update at any time.</p>
          </div>
        </div>
      </Centered>

      <Reviews />
      
      <Features />

      <Pricing />

      <Examples />

      <Spacer amount="260" />

      <Centered className="max-width-spacing-screen-normal">
        <p className="title2 mt-spacing-l">🦁</p>
        <h2>Join the tribe</h2>
        <p>
          When you join Bravemark, you also become part of our tribe. That means you will be a part of our community and that it’s important for us to hear what you have to say so that we can learn how we can build features that are important to you!</p>
        <JoinButton className="mt-spacing-m" />
        <p className="grey-500">No credit card required</p>
      </Centered>

      {/* TODO sign up newsletter */}

      <Spacer />
    </Page>
  )
}

function JoinButton(props) {
  return <Button to="https://app.bravemark.co/editor/register" large label="Get started for Free" width="265px" {...props} />
}

function Reviews() {
  return (
    <>
      <a id="reviews"></a>
      <Spacer amount="170"/>
      <Centered>
        <Testimonials senjaDataId={SenjaTestimonialsId}>
          <div className="max-width-spacing-screen-small">
            <p className="mb-spacing-l">Want to see more?<br/>Read all our customer reviews on our wall of love.</p>
            <Button large label="Go to our Wall of Love" to="https://love.bravemark.co/" width="245px" />
          </div>
        </Testimonials>
      </Centered>
    </>
  )
}

function Features() {
  const spacer = <Spacer amount="320" className="featuresSpacer" />

  return (
    <>
      <a id="features"></a>
      <Spacer amount="260" />
      <Centered className="max-width-spacing-screen-big">
        <h2>Step up your game and impress your clients with the brand identity handoff solution of the future</h2>
        <p className="mt-spacing-xl max-width-spacing-screen-big">Bringing practicality to the brand identity design workflow. Discover the powerful features and benefits Bravemark offers to brand identity designers and the brands they serve.</p>
      </Centered>
      <Spacer amount="140" />
      
      <Double title="Responsive Brand Guidelines, at last" imageUrl={BrandingPresentationImage} imageAlt="Brand guidelines on different devices">
        <p>Say goodbye to boring PDF brand guidelines and deliver responsive online brand guidelines that are perfectly optimized for any device automagically. Marketers,  content creators, and business owners will thank you.</p>
      </Double>

      {spacer}

      <Double title="Simplify your client’s workflow with interactive brand guidelines" imageUrl={AssetsImage} imageAlt="Brand identity guideline modules" reverseOrder={true}>
        <p>Brand guidelines should be an actionable resource, not just something to read through. Select individual brand assets, only download the files you need, and easily copy color HEX codes for seamless design work. Add notes to individual assets with valuable context and usage information.</p>
      </Double>

      {spacer}

      <Double title="Design customized brand guidelines with our No-Code editor in no time" imageUrl={BuilderImage} imageAlt="Brand guideline builder">
        <p>Easily design online brand guidelines your way with our intuitive drag-and-drop builder. No need to have any coding knowledge; just use our easy UI to customize it to your liking and publish it online with just a few clicks.</p>
      </Double>

      {spacer}

      <Double title="Centralize all your brand assets on a single page" imageUrl={AssetsModulesImage} imageAlt="Example of assets modules" reverseOrder={true}>
        <p>Easily upload and organize brand assets and file formats by simply dropping them into dedicated use-case modules. Make it easy for your clients to preview and download exactly what they need, whether as individual file formats or bundled together in a single global ZIP file.</p>
      </Double>

      {spacer}

      <Double title="Hand off your work by simply sharing a URL" imageUrl={SharingLogosImage} imageAlt="Share brand guideline URL">
        <p>After publishing your #brandpage, share it with your clients through a public or private URL. Need to make updates? Simply update it without the hassle of exporting PDFs or sending emails. It's seamless, efficient, and hassle-free.</p>
      </Double>

      {spacer}

      <Double title="Showcase your brand identity work and get discovered by others" imageUrl={ShowcaseImage} imageAlt="Showcasing your brand identity design work" reverseOrder={true}>
        <p>Expand your professional circle by exhibiting your brand identity design projects and gain recognition from the design community. Stay inspired by exploring the latest trends and connect with esteemed designers.</p>
      </Double>
    </>
  )
}

function Pricing() {
  return (
    <>
      <a id="pricing"></a>
      <Spacer amount="220" />
      <Centered>
        <h2>Start creating powerful brand guidelines</h2>
        <p className="max-width-spacing-screen-normal">Sign up for a free account today and only upgrade to a PRO or PRO+ account when you know you are ready for more features and projects.</p>
      </Centered>
      
      <Spacer amount="40" />

      <Centered className="max-width-spacing-screen-max">
      
        <PaywallContainer />

      </Centered>

      <Spacer amount="50" />

      <Centered className="max-width-spacing-screen-normal">
        <p className="grey-300">Our pricing is intended to give designers of all levels and budgets an affordable way to create actionable online brand guidelines for their clients.</p>

        <Spacer amount="5" />

        <p>Need more projects? Contact us at <a href="hello@bravemark.co">hello@bravemark.co</a> for a custom plan.</p>
      </Centered>
    </>
  )
}

function Examples() {
  return (
    <>
      <a id="examples"></a>
      <Spacer amount="220" />
      <Centered>
        <h2>What other designers are making</h2>
        <p className="max-width-spacing-screen-small">Discover brand identity projects other designers have published to their portfolios on Bravemark.</p>
      </Centered>
      
      <Spacer amount="40" />

      <ExampleGrid data={ExamplesData} />

      <Spacer amount="50" />

      <Centered className="max-width-spacing-screen-small">
        <p>Find inspiration for your next project on Bravemark.</p>
        <Button large label="See what others are making" to="https://app.bravemark.co/discover" width="320px" />
      </Centered>
    </>
  )
}

export default IndexPage
