import React from "react";
import * as moduleStyles from "./OnOffButton.module.scss";


export default function OnOffButton({active=false, disabled=false, onChange}) {

  let classNames = [
    moduleStyles.OnOffButton,
    active ? moduleStyles.uActive : null,
    disabled === true ? moduleStyles.uDisabled : null,
  ].join(" ");

  function handleClick(e) {
    onChange(!active);
  }

  return (
    <div className={classNames} onClick={handleClick}>
      <div className={moduleStyles.inner}>
        <div className={moduleStyles.pin} />
      </div>
    </div>
  )
}