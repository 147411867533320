import React from "react";

// Examples
import exampleImage01 from "./images/examples/andys@2x.png";
import exampleImage02 from "./images/examples/designer-hikes@2x.png";
import exampleImage03 from "./images/examples/nomster@2x.png";
import exampleImage04 from "./images/examples/outer@2x.png";
import exampleImage05 from "./images/examples/erone@2x.png";
import exampleImage06 from "./images/examples/manchester-psychedelic-community@2x.png";
import exampleImage07 from "./images/examples/webb@2x.png";
import exampleImage08 from "./images/examples/logofolio@2x.png";
import exampleImage09 from "./images/examples/serotiny@2x.png";
import exampleImage10 from "./images/examples/popinato@2x.png";
import exampleImage11 from "./images/examples/brunos-pizza@2x.png";
import exampleImage12 from "./images/examples/evolv@2x.png";


// Designers
import designerOtmani from "./images/designers/designer-ali-otmani.png";
import designerCucuk from "./images/designers/designer-aleksandar-cucukovic.png";
import designerMcCarthy from "./images/designers/designer-karl-mccarthy.png";
import designerJohansson from "./images/designers/designer-anthon-johansson.png";
import designerTanic from "./images/designers/designer-bozidar-tanic.png";
import designerAndrei from "./images/designers/designer-andrei.png";
import designerAdam from "./images/designers/designer-adam.jpg";
import designerAngela from "./images/designers/designer-angela.png";
import designerZalo from "./images/designers/designer-zalo.jpg";
import designerGrimes from "./images/designers/designer-jordan-grimes.png";
import designerKyle from "./images/designers/designer-kyle.jpg";


export const ExamplesData = [
  {
    projectPath: "bekah-chapman/andysclassicengineering",
    title: "Andy's Classic Engineering",
    imgUrl: exampleImage01,
    color: "#151515"
  },
  {
    projectPath: "jameswestdesign/designerhikes",
    title: "Designer Hikes",
    imgUrl: exampleImage02,
    color: "#621E82"
  },
  {
    projectPath: "karlmccarthydesign/nomster",
    title: "Nomster",
    imgUrl: exampleImage03,
    color: "#ffffff"
  },
  {
    projectPath: "oakfold/outer",
    title: "Outer",
    imgUrl: exampleImage04,
    color: "#ffffff"
  },
  {
    projectPath: "tecky/erone",
    title: "Erone",
    imgUrl: exampleImage05,
    color: "#ffd800"
  },
  {
    projectPath: "loveandlogic/manchester-psychedelic-community",
    title: "Manchester Psychedelic Community",
    imgUrl: exampleImage06,
    color: "#DB067B"
  },
  {
    projectPath: "jsteneros/webb",
    title: "Webb",
    imgUrl: exampleImage07,
    color: "#D63C2B"
  },
  {
    projectPath: "jsteneros/logofolio",
    title: "Logofolio",
    imgUrl: exampleImage08,
    color: "#000"
  },
  {
    projectPath: "btanic/serotiny-publishing",
    title: "Serotiny Publishing",
    imgUrl: exampleImage09,
    color: "#5C7EFF"
  },
  {
    projectPath: "zalo0/popinato",
    title: "Popinato",
    imgUrl: exampleImage10,
    color: "#FFFFFF"
  },
  {
    projectPath: "zbrda-zdola/brunospizza",
    title: "Bruno's Pizza",
    imgUrl: exampleImage11,
    color: "#202028"
  },
  {
    projectPath: "artangent/3volv",
    title: "3VOLV",
    imgUrl: exampleImage12,
    color: "#467227"
  },
]

export const DesignersHero = [
  { path: designerZalo, href: "https://app.bravemark.co/zalo0" },
  { path: designerOtmani, href: "https://app.bravemark.co/itsalielo" },
  { path: designerCucuk, href: "https://app.bravemark.co/alexondesign" },
  { path: designerMcCarthy, href: "https://app.bravemark.co/karlmccarthydesign" },
  { path: designerJohansson, href: "https://app.bravemark.co/anthonjohansson" },
  { path: designerTanic, href: "https://app.bravemark.co/btanic" },
];

export const SenjaTestimonialsId = null // "4192376a-f4ec-4dd1-854e-c7dd3f083f8b"

export const customTestimonials = [
  {
    image: designerAngela,
    link: "https://app.bravemark.co/loveandlogic",
    name: "Angela Roche",
    job: "Creative Director",
    text: <p>Just added my first brand to Bravemark! I've been looking for the right brand management/guidelines platform for ages and my search is over! Loving Bravemark ❤️</p>,
    date: "Dec 2, 2023",
  },
  {
    image: designerZalo,
    link: "https://app.bravemark.co/zalo0",
    name: "Zalo Estévez",
    job: "Logo & Visual Identity Designer",
    text: <p>Incredible user experience! Super intuitive and aesthetic. The perfect tool to deliver brand guidelines in an easy and very comfortable way to the customer. 100% Recommended!</p>,
    date: "Sep 19, 2023",
  },
   {
    image: designerAdam,
    link: "https://app.bravemark.co/oakfold",
    name: "Adam Greasley",
    job: "Brand and type designer",
    text: <p>As a brand designer, being able to hand over an online brandpage to a client is super useful. Bravemark makes this process extremely quick and easy.</p>,
    date: "Dec 4, 2023",
  },
  {
    image: designerAndrei,
    link: "https://app.bravemark.co/artangent",
    name: "Andrei Traista",
    job: "Brand Identity Designer",
    text: <p>Glad I stumbled upon Bravemark on Twitter, never seen something similar. Even though the platform is just taking it's first stepts, it has huge potential and could become a really cool tool for brand identity designers</p>,
    date: "Apr 14, 2023",
  },
  {
    image: designerJohansson,
    link: "https://app.bravemark.co/anthonjohansson",
    name: "Anthon Johansson",
    job: "Graphic Designer",
    text: <p>The team behind Bravemark genuinely care about their product and I believe that has helped them to create a really easy-to-use service. Whilst Bravemark is still in its early stages, the future is very promising and the speed a presentation can be completed is staggering compared to all the competition.</p>,
    date: "May 8, 2023",
  },
  {
    image: designerMcCarthy,
    link: "https://app.bravemark.co/karlmccarthydesign",
    name: "Karl McCarthy",
    job: "Brand Identity Designer",
    text: <p>Really easy interface to navigate. I found myself quickly snapping modules together, getting into a quick rhythm building presentations. Overall I'm very impressed!</p>,
    date: "Dec 16, 2022",
  },
  {
    image: designerTanic,
    link: "https://app.bravemark.co/btanic",
    name: "Bozidar Tanic",
    job: "Brand Identity Designer",
    text: <p>I've been using Bravemark for a week now and I'm happy with it. It's a great alternative to the best well-known platforms out there and it's perfect for what I need. It's hassle-free and easy to use, which is exactly what I was looking for.</p>,
    date: "Dec 11, 2022",
  },
  {
    image: designerKyle,
    link: "https://app.bravemark.co/kcourtright",
    name: "Kyle Courtright",
    job: "Brand Identity Designer",
    text: <p>Bravemark is changing the way identity designers present their work and I'm loving it. Modern, intuitive and robust. No doubt will continue to be a disrupter in the creative space. Well done!</p>,
    date: "Apr 14, 2023",
  },
  {
    image: designerGrimes,
    link: "https://app.bravemark.co/j7grimes",
    name: "Jordan Grimes",
    job: "Brand Identity Designer",
    text: <p>Getting the chance to utilize Bravemark (beta) was a great experience. I was used to creating brand guidelines in InDesign manually so it was a breath of fresh air to essentially drag and drop a guidelines page.</p>,
    date: "Dec 11, 2022",
  },
]